import React, { useState } from "react"
import Image from "../image"
import PropTypes from "prop-types"
import { linkPropTypes, mediaPropTypes } from "@/utils/types"
import CustomLink from "./custom-link"
import classNames from "classnames"
import "./footer.css"
import axios from "axios"
import validator from "validator"

const Footer = ({ footer }) => {
  return (
    <footer className="pt-12">
      <div className="container">
        {/* <iframe
          name="hiddenFrame"
          width="0"
          height="0"
          border="0"
          style="display: none;"
        ></iframe> */}
        <div
          className="flex flex-col md:flex-row py-9 md:py-16"
          style={{ borderTop: "1px solid #EFF1FE" }}
        >
          <div className="py-9 md:py-0">
            {footer.logo && (
              <Image style={{ width: "146px" }} media={footer.logo} />
            )}
          </div>
          <div className="md:ml-auto">
            <FormSubmission></FormSubmission>
          </div>
        </div>
        <nav className="flex flex-wrap flex-col md:flex-row lg:gap-20 items-start mb-10">
          {footer.columns.map(footerColumn => (
            <div
              key={footerColumn.id}
              className={classNames("mt-10 lg:mt-0 md:w-6/12 lg:w-auto", {
                "md:ml-auto": footerColumn.title == "Follow us",
                "md:pl-20": footerColumn.title == "Address",
              })}
            >
              <p className="tracking-wide font-semibold sub-header footer-heading">
                {footerColumn.title}
              </p>
              {footerColumn.title != "Follow us" ? (
                <ul className="mt-2">
                  {footerColumn.title != "Address" ? (
                    footerColumn.links.map((link, i) => (
                      <li
                        key={link.id}
                        className="text-gray-700 py-1 px-1 -mx-1 hover:text-gray-900 sub-text"
                      >
                        <CustomLink link={link} key={i}>
                          {link.text}
                        </CustomLink>
                      </li>
                    ))
                  ) : (
                    <CustomLink link={footerColumn.links[0]}>
                      {footerColumn.links.map((link, i) => (
                        <span
                          key={link.id}
                          className="text-gray-700 py-1 px-1 -mx-1 sub-text"
                        >
                          {link.text} <br />
                          {/* <CustomLink link={link} key={i}>
                            {link.text}
                          </CustomLink> */}
                        </span>
                      ))}
                    </CustomLink>
                  )}
                </ul>
              ) : (
                <div className="flex gap-2">
                  {footerColumn.links.map(link => (
                    // <li
                    //   key={link.id}
                    //   className="text-gray-700 py-1 px-1 -mx-1 hover:text-gray-900 sub-text"
                    // >
                    //   <CustomLink link={link}>{link.text}</CustomLink>
                    // </li>
                    <CustomLink link={link} key={link.id}>
                      {link.logo && (
                        <Image
                          placeholder="none"
                          style={{ width: "36px" }}
                          media={link.logo}
                          className="p-6"
                        />
                      )}
                    </CustomLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
      <div className="text-sm bg-gray-200 py-6 text-gray-700 text-center">
        <div className="container">{footer.smallText}</div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  footer: PropTypes.shape({
    logo: mediaPropTypes.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        title: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(linkPropTypes),
      })
    ),
    smallText: PropTypes.string.isRequired,
  }),
}

class FormSubmission extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      name: "",
      submited: false,
      emailError: "",
    }
  }

  handleEmailInputChanged(event) {
    this.setState({
      email: event.target.value,
    })
  }
  handleNameInputChanged(event) {
    this.setState({
      name: event.target.value,
    })
  }

  handleButtonClicked(event) {
    event.preventDefault()
    var searchQuery = this.state
    // this.state.submitted = true
    if (this.validateEmail(this.state.email) && !this.state.submited) {
      const { data } = axios
        .post("https://cms.evplugs.co.in/lead-form-submissions", this.state)
        .then(res => {
          this.setState({
            submited: "green",
          })
        })
    }

    // window.location.href =
    //   "https://youtube.com/results?search_query" + searchQuery
  }

  // const [emailError, setEmailError] = useState('')

  validateEmail = email => {
    var email = email
    if (validator.isEmail(email)) {
      return true
    } else {
      this.setState({
        emailError: "Email is invalid",
      })
      return false
    }
  }

  render() {
    return (
      <form
        // method="post"
        // action="https://cms.evplugs.co.in/lead-form-submissions"
        className="flex flex-col md:flex-row gap-4"
        target="hiddenFrame"
      >
        <div className="email">
          <input
            style={{ height: 48 }}
            placeholder="Email"
            className="evp-input px-16px py-14px md:width-320px"
            type="email"
            name="email"
            onChange={this.handleEmailInputChanged.bind(this)}
          />
          <span>{this.state.emailError}</span>
        </div>
        <input
          style={{ height: 48 }}
          placeholder="Name"
          className="evp-input px-16px py-14px md:width-320px"
          type="text"
          name="name"
          onChange={this.handleNameInputChanged.bind(this)}
        />
        <button
          style={{ padding: "12px 30px", height: 48 }}
          // type="submit"
          // className={
          //   "block w-full lg:w-auto text-center uppercase tracking-wide font-semibold text-base evp-primary md:text-sm rounded-md  text-white button-text"
          // }
          className={classNames(
            "block w-full lg:w-auto text-center uppercase tracking-wide font-semibold text-base evp-primary md:text-sm rounded-md  text-white button-text",
            {
              "btn-color-success": this.state.submited,
            }
          )}
          // className={ 'btn-color': this.state.bgColor }
          onClick={this.handleButtonClicked.bind(this)}
        >
          {this.state.submited ? "Subscribed !" : "Subscribe"}
        </button>
      </form>
    )
  }
}
export default Footer
